import React, { useState } from "react";
import Profile from "../../modals/Profile";
const RoomChatList: React.VFC = ({ chat, current_user_id }) => {
  const [show, setShow] = useState(false);
  if (chat.user == undefined) {
    return <></>;
  }
  return (
    <>
      {chat.user_id == current_user_id ? (
        <>
          <Profile user={chat.user} show={show} setShow={setShow} />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="card w-100 me-3">
              <div className="card-header d-flex justify-content-between p-2 bg-teal-200">
                <p className="fw-bold mb-0 fs-7">
                  {chat.user.sei_kana}&nbsp;{chat.user.mei_kana}
                </p>
                <p className="fs-7 mb-0">
                  <i className="far fa-clock"></i> {chat.elapsed_time_str}
                </p>
              </div>
              <div className="card-body bg-teal-200 p-2">
                <p className="mb-0">{chat.comment}</p>
              </div>
            </div>
            <div className="ms-auto">
              <div
                className="thumb_img rounded-circlebg-white rounded-circle"
                style={{
                  backgroundImage: `url(${chat.user.avatar_url})`,
                  border: `4px solid ${chat.icon_border_color}`,
                }}
                onClick={() => {
                  setShow(true);
                }}
              ></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Profile user={chat.user} show={show} setShow={setShow} />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="me-3">
              <div
                className="thumb_img rounded-circlebg-white rounded-circle"
                style={{
                  backgroundImage: `url(${chat.user.avatar_url})`,
                  border: `4px solid ${chat.icon_border_color}`,
                }}
                onClick={() => {
                  setShow(true);
                }}
              ></div>
            </div>
            <div className="card me-auto w-100">
              <div className="card-header d-flex justify-content-between p-2 bg-white">
                <p className="fw-bold mb-0 fs-7">
                  {chat.user.sei_kana}&nbsp;{chat.user.mei_kana}
                </p>
                <p className="text-muted fs-7 mb-0">
                  <i className="far fa-clock"></i> {chat.elapsed_time_str}
                </p>
              </div>
              <div className="card-body p-2">
                <p className="mb-0 chat-comment">{chat.comment}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default RoomChatList;
