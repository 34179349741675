import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const DateRangeInput: React.VFC = ({ from_name, to_name }) => {
  var from_day = new Date();
  var to_day = new Date();
  to_day.setDate(to_day.getDate() + 1);
  const [from, setFrom] = useState(from_day);
  const [to, setTo] = useState(to_day);
  registerLocale("ja", ja);

  const onChangeFrom = (date) => {
    //console.log("onChangeFrom", date);
    setFrom(date);
  };
  const onChangeTo = (date) => {
    //console.log("onChangeTo", date);
    setTo(date);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="pe-1">
          <DatePicker
            selected={from}
            className="form-control"
            onChange={onChangeFrom}
            dateFormat="yyyy/MM/dd"
            locale="ja"
          />
        </div>
        <div> ~ </div>
        <div className="ps-1">
          <DatePicker
            selected={to}
            className="form-control"
            onChange={onChangeTo}
            dateFormat="yyyy/MM/dd"
            locale="ja"
          />
        </div>
      </div>
      <input
        type="hidden"
        name={from_name}
        value={dayjs(from).format("YYYY-MM-DD")}
      />
      <input
        type="hidden"
        name={to_name}
        value={dayjs(to).format("YYYY-MM-DD")}
      />
    </>
  );
};
export default DateRangeInput;
