import React, { useState, useEffect } from "react";
import { updateUserNotifySetting } from "../../libs/api/user";
import QuestionTooltip from "./partials/QuestionTooltip";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const UserNotifySetting: React.VFC = ({ user }) => {
  const [showToast, setShowToast] = useState(false);

  const [lineNotify, setLineNotify] = useState(user.line_notify);
  const [emailNotify, setEmailNotify] = useState(user.email_notify);

  const [roomLineNotify, setRoomLineNotify] = useState(user.room_line_notify);
  const [roomEmailNotify, setRoomEmailNotify] = useState(
    user.room_email_notify
  );

  const [chatLineNotify, setChatLineNotify] = useState(user.chat_line_notify);
  const [chatEmailNotify, setChatEmailNotify] = useState(
    user.chat_email_notify
  );

  const [notificationLineNotify, setNotificationLineNotify] = useState(
    user.notification_line_notify
  );
  const [notificationEmailNotify, setNotificationEmailNotify] = useState(
    user.notification_email_notify
  );

  const [reviewLineNotify, setReviewLineNotify] = useState(
    user.review_line_notify
  );
  const [reviewEmailNotify, setReviewEmailNotify] = useState(
    user.review_email_notify
  );

  useEffect(() => {}, [user]);

  const canUpdate = () => {
    return lineNotify || emailNotify;
  };

  const submit = async () => {
    const params = {
      line_notify: lineNotify,
      email_notify: emailNotify,
      room_line_notify: roomLineNotify,
      room_email_notify: roomEmailNotify,
      chat_line_notify: chatLineNotify,
      chat_email_notify: chatEmailNotify,
      notification_line_notify: notificationLineNotify,
      notification_email_notify: notificationEmailNotify,
      review_line_notify: reviewLineNotify,
      review_email_notify: reviewEmailNotify,
    };
    await updateUserNotifySetting(params);
    setShowToast(true);
  };

  return (
    <>
      <h3 className="fs-6 fw-bold">
        <span className="me-1">部屋のリマインド通知</span>
        <QuestionTooltip
          text={`開催時間前にリマインド通知が届きます。また開催者に対しては開催者ステータス設定のリマインド通知が届きます。`}
          placement={`bottom`}
          keyName={`default_notify`}
        />
      </h3>
      <div className="d-flex mt-1">
        <div>
          <div className="form-check form-switch form-check-inline me-4 fs-7">
            <input
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={emailNotify}
              onChange={(e) => setEmailNotify(e.target.checked)}
            />
            <label className="form-check-label">メールアドレスに通知</label>
          </div>
        </div>
      </div>

      <h3 className="fs-6 fw-bold mt-4">
        <span className="me-1">部屋の参加・退出通知</span>
        <QuestionTooltip
          text={`開催者に対して参加者の参加時、退出時の通知が届きます。また開催者・参加者に対して満室時の通知がされます。`}
          placement={`bottom`}
          keyName={`line_notify`}
        />
      </h3>
      <div className="d-flex mt-1">
        <div>
          <div className="form-check form-switch form-check-inline me-4 fs-7">
            <input
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={roomEmailNotify}
              onChange={(e) => setRoomEmailNotify(e.target.checked)}
            />
            <label className="form-check-label">メールアドレスに通知</label>
          </div>
        </div>
      </div>

      <h3 className="fs-6 fw-bold mt-4">
        <span className="me-1">掲示板更新通知</span>
        <QuestionTooltip
          text={`部屋の掲示板に対しての通知です。`}
          placement={`bottom`}
          keyName={`chat_notify`}
        />
      </h3>
      <div className="d-flex mt-1">
        <div>
          <div className="form-check form-switch form-check-inline me-4 fs-7">
            <input
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={chatEmailNotify}
              onChange={(e) => setChatEmailNotify(e.target.checked)}
            />
            <label className="form-check-label">メールアドレスに通知</label>
          </div>
        </div>
      </div>

      <h3 className="fs-6 fw-bold mt-4">
        <span className="me-1">お知らせの更新通知</span>
        <QuestionTooltip
          text={`重要なお知らせに関しての通知です。`}
          placement={`bottom`}
          keyName={`notification_notify`}
        />
      </h3>
      <div className="d-flex mt-1">
        <div>
          <div className="form-check form-switch form-check-inline me-4 fs-7">
            <input
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={notificationEmailNotify}
              onChange={(e) => setNotificationEmailNotify(e.target.checked)}
            />
            <label className="form-check-label">メールアドレスに通知</label>
          </div>
        </div>
      </div>

      <h3 className="fs-6 fw-bold mt-4">
        <span className="me-1">レビュー受け取り通知</span>
        <QuestionTooltip
          text={`レビューに関しての通知です。`}
          placement={`bottom`}
          keyName={`review_notify`}
        />
      </h3>
      <div className="d-flex mt-1">
        <div>
          <div className="form-check form-switch form-check-inline me-4 fs-7">
            <input
              className="form-check-input"
              type="checkbox"
              value="1"
              checked={reviewEmailNotify}
              onChange={(e) => setReviewEmailNotify(e.target.checked)}
            />
            <label className="form-check-label">メールアドレスに通知</label>
          </div>
        </div>
      </div>
      <div className="d-grid col-md-6 col-12 mx-auto mt-5">
        <button
          type="button"
          className="btn-lg rounded-pill btn btn-primary"
          onClick={() => submit()}
        >
          変更する
        </button>
      </div>
      <ToastContainer className="p-3 position-fixed" position={`top-end`}>
        <Toast
          className="bg-success"
          show={showToast}
          onClose={() => setShowToast(false)}
        >
          <Toast.Header>
            <strong className="me-auto text-success">
              <i className="fas fa-check me-2" />
              成功
            </strong>
          </Toast.Header>
          <Toast.Body className="text-white">通知設定を更新しました</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
export default UserNotifySetting;
