import React, { useState, useEffect } from "react";
type Props = {
  path: string;
  noVacancy: boolean;
};
const ShowNoVacancySwitch: React.VFC<Props> = (props) => {
  const [noVacancy, setNoVacancy] = useState(props.noVacancy);

  const onChange = (e) => {
    setNoVacancy(e.target.checked);
    if (e.target.checked) {
      location.href = `${props.path}`;
    } else {
      location.href = `${props.path}?vacancy=1`;
    }
  };

  return (
    <div className="form-check form-switch form-check-inline my-3 fs-6">
      <input
        className="form-check-input"
        type="checkbox"
        value="1"
        checked={noVacancy}
        onChange={(e) => onChange(e)}
      />
      <label className="form-check-label">満室の部屋も表示</label>
    </div>
  );
};
export default ShowNoVacancySwitch;
