import React, { useState } from "react";
import Profile from "../modals/Profile";
const ThumbImage: React.VFC = ({ user }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Profile user={user} show={show} setShow={setShow} />
      <div
        onClick={() => {
          setShow(true);
        }}
        className="thumb_img rounded-circlebg-white rounded-circle"
        style={{
          backgroundImage: `url(${user.avatar_url})`,
          border: `4px solid ${user.icon_border_color}`,
        }}
      ></div>
    </>
  );
};
export default ThumbImage;
