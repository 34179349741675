import React, { useState } from "react";
import Profile from "../modals/Profile";
const UserList: React.VFC = ({ user }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Profile user={user} show={show} setShow={setShow} />
      <div className="d-flex justify-content-center align-items-center mt-2">
        <div className="me-3">
          <div
            className="thumb_img rounded-circlebg-white rounded-circle"
            style={{
              backgroundImage: `url(${user.avatar_url})`,
              border: `4px solid ${user.icon_border_color}`,
            }}
            onClick={() => {
              setShow(true);
            }}
          ></div>
        </div>
        <div className="me-auto">
          {user.kinds == "company" ? (
            <div className="fw-bold">
              {user.sei_kana} {user.mei_kana} さん (&nbsp;
              {user.affiliation_industry}
              &nbsp;/&nbsp;
              {user.department}&nbsp;)
            </div>
          ) : (
            <div className="fw-bold">
              {user.sei_kana} {user.mei_kana} さん ({user.graduate_year}年卒)
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UserList;
